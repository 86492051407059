<template>
  <div class="resource-admin">
    <h4 text-left>资源统计</h4>
    <div class="row mt-3 align-items-center">
      <div class="col-auto">
        <label for="start" class="col-form-label">日期</label>
      </div>
      <div class="col-auto">
        <input type="date" name="start" id="start" class="form-control" value="2021-01-05">
      </div>
      <div class="col-auto">
        <input type="date" name="end" id="end" class="form-control" value="2021-01-10">
      </div>
      <div class="col-auto">
        <label for="source" class="col-form-label">来源</label>
      </div>
      <div class="col-auto">
        <select class="form-control" name="source" id="source">
          <option disabled selected>请选择</option>
          <option value="1">美团</option>
          <option value="2">点评</option>
          <option value="3">抖音</option>
          <option value="4">小红书</option>
          <option value="5">头条</option>
        </select>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-primary">筛选</button>
      </div>
    </div>
    <table class="table table-striped mt-3">
      <thead>
      <tr>
        <th>日期</th>
        <th>资源量</th>
        <th>成交</th>
        <th>付款</th>
        <th>无效</th>
        <th>未成交</th>
        <th>再跟进</th>
        <th>邀约进店</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in list" v-bind:key="item.index">
        <td v-for="number in item" v-bind:key="number.index">{{ number }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'ResourceStatistics',
  components: {
  },
  data(){
    return {
      show: false,
      list: [
        ['美团', 100, 100, 100, 100, 100, 100, 10],
        ['点评', 100, 100, 100, 100, 100, 100, 10],
        ['抖音', 100, 100, 100, 100, 100, 100, 10],
        ['头条', 100, 100, 100, 100, 100, 100, 10],
        ['依慧', 100, 100, 100, 100, 100, 100, 10],
        ['其他', 100, 100, 100, 100, 100, 100, 10],
      ]
    }
  },
  filters: {
    ellipsis(value) {
      if(!value) return '';
      if(value.length > 20) {
        return value.slice(0, 20) + '...'
      }
      return  value;
    }
  },
  methods: {
    addRecord(id) {
      console.log(id)
      this.show = true;
    },
    hideModal() {
      console.log('here');
      this.show = false;
    }
  }
}
</script>